import { snowplowAnalyticsProperties } from '../properties/frontend';
import logger from '../logger';
import { Language } from '@yleisradio/areena-types';
import { Consent } from 'types/yleConsentSdk';
import { yleConsentGenerator, yleContextGenerator } from './contextGenerators';

const initSnowplowTracker = (language: Language): void => {
  if (!window.yleSnowplow) {
    throw new Error('Snowplow tracker is not available');
  }

  const { appId, collectorUrl, trackerNamespace } = snowplowAnalyticsProperties;

  window.yleSnowplow('newTracker', trackerNamespace, collectorUrl, {
    appId: appId,
    discoverRootDomain: true,
    cookieSameSite: 'Lax',
    contexts: {
      browser: true,
    },
    platform: 'web',
    anonymousTracking: { withSessionTracking: true }, // init with anonymous tracking
  });
  logger.debug('Snowplow tracker activated');
  window.yleSnowplow('addGlobalContexts', [yleContextGenerator(language)]);
};

const addConsentContext = (consent: Consent) => {
  if (!window.yleSnowplow) {
    throw new Error('Snowplow tracker is not available');
  }

  const contexts = [yleConsentGenerator(consent)];
  window.yleSnowplow('disableAnonymousTracking'); // disable anonymous tracking when any consent is given
  window.yleSnowplow('addGlobalContexts', contexts);
};

const clearUser = () => {
  if (!window.yleSnowplow) {
    throw new Error('Snowplow tracker is not available');
  }

  window.yleSnowplow('clearUserData');
  window.yleSnowplow('newSession');
};

const setUser = (userId: string) => {
  if (!window.yleSnowplow) {
    throw new Error('Snowplow tracker is not available');
  }

  window.yleSnowplow('disableAnonymousTracking'); // disable anonymous tracking when any user is authenticated
  window.yleSnowplow('setUserId', userId);
};

const trackPageView = () => {
  if (!window.yleSnowplow) {
    throw new Error('Snowplow tracker is not available');
  }

  window.yleSnowplow('trackPageView');
};

export {
  initSnowplowTracker,
  addConsentContext,
  setUser,
  clearUser,
  trackPageView,
};
