import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useRef,
  useMemo,
} from 'react';
import Script from 'next/script';
import { useTunnusContext } from 'contexts/TunnusContext';
import { apmRum } from 'services/apm-rum';
import {
  initSnowplowTracker,
  addConsentContext,
  setUser,
  clearUser,
} from 'services/snowplowAnalytics/snowplowAnalytics';
import logger from 'services/logger';
import { useCookieConsent } from 'contexts/CookieConsentContext';
import { useUILanguage } from 'hooks/useUILanguage';
import { isTestEnvironment } from 'utils/environment';
import { snowplowAnalyticsProperties } from 'services/properties/frontend';

declare global {
  interface Window {
    yleSnowplow?: (method: string, ...args: unknown[]) => void;
    yleSnowplowReady?: Promise<void> | undefined;
    GlobalSnowplowNamespace?: string[];
  }
}

type SnowplowContextType = {
  isSnowplowReady: boolean;
};

const SnowplowContext = createContext<SnowplowContextType>({
  isSnowplowReady: false,
});

type SnowplowProviderProps = {
  children: ReactNode;
};

export const SnowplowProvider: React.FC<SnowplowProviderProps> = ({
  children,
}) => {
  const language = useUILanguage();
  const consent = useCookieConsent();
  const { userInformation: { userId } = {}, isAuthenticated } =
    useTunnusContext();

  const [isSnowplowScriptLoaded, setSnowplowScriptLoaded] = useState(false);
  const [isSnowplowInitialized, setSnowplowInitialized] = useState(false);

  const resolveRef = useRef<(() => void) | null>(null);
  const rejectRef = useRef<(() => void) | null>(null);

  const contextValue = useMemo(
    (): SnowplowContextType => ({ isSnowplowReady: isSnowplowInitialized }),
    [isSnowplowInitialized]
  );

  const isFullTrackingAllowed = !!consent;

  useEffect(() => {
    if (!isTestEnvironment()) return; // We only run snowplow in test environment for now

    if (!window.yleSnowplowReady) {
      window.yleSnowplowReady = new Promise<void>((resolve, reject) => {
        resolveRef.current = resolve;
        rejectRef.current = reject;
      });
    }
  }, []);

  // init snowplow with anonymous tracking
  useEffect(() => {
    if (!isSnowplowScriptLoaded) return;

    try {
      initSnowplowTracker(language);
      setSnowplowInitialized(true);
      resolveRef.current?.();
    } catch (error) {
      logger.error(error, 'Failed to initialize Snowplow tracker');
      if (error instanceof Error) apmRum?.captureError(error);
      rejectRef.current?.();
    }
  }, [isSnowplowScriptLoaded, language]);

  // update consent context
  useEffect(() => {
    if (isSnowplowInitialized && consent) {
      addConsentContext(consent);
    }
  }, [consent, isSnowplowInitialized]);

  // set user id / clear user
  useEffect(() => {
    if (!isSnowplowInitialized) return;

    if (isFullTrackingAllowed && isAuthenticated && userId) {
      setUser(userId);
    } else {
      clearUser();
    }
  }, [isAuthenticated, isFullTrackingAllowed, isSnowplowInitialized, userId]);

  return (
    <SnowplowContext.Provider value={contextValue}>
      {isTestEnvironment() && (
        <Script
          id="snowplow-load-script"
          onLoad={() => setSnowplowScriptLoaded(true)}
        >
          {`;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[]; p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments) };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1; n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script",${JSON.stringify(snowplowAnalyticsProperties.trackerUrl)},"yleSnowplow"));`}
        </Script>
      )}
      {children}
    </SnowplowContext.Provider>
  );
};

export function useSnowplow(): SnowplowContextType {
  return useContext(SnowplowContext);
}
